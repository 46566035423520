import { en_us } from "../../core/lang/en-us";
import { Lang } from "../../core/services/Lang/types";

const enUS: Lang = {
    ...en_us,
    modules: {
        dashboard: {
            name: "Dashboard",
            texts: {
                bemvindo: 'Welcome to Boa Gestão',
                periodo: 'Period',
                essaSemana: 'This week',
                semanaPassada: 'Last week',
                esseMes: 'This month',
                mesPassado: 'Last month',
                esseAno: 'This year',
                anoPassado: 'Last year',
                vendedor: 'Salesman',
                dataInicial: 'Start Date',
                dataFinal: 'End Date',
                allSalesman: 'All Salesman'
            },
            options: {
                vendas: `Sales`,
                helpdesk: `Helpdesk`,
                Financeiro: `Banking`,
                logistica: 'Logistics',
            },
            vendas: {
                name: 'Sales',
                cards: {
                    TotalVendas: 'Sales Total',
                    QtdVendas: 'Sales Count',
                    QtdClientes: 'Customers Served',
                    TicketMedio: 'Average Ticket',
                    LucroTotal: 'Profit Total'
                },
                graphs: {
                    texts: {
                        semana: 'week',
                    },
                    MaioresCompradores: 'Best Customers',
                    ProdutosMaisRentaveis: 'Best Products',
                    VendasPorVendedor: 'By Salesman'

                },
                dialogs: {
                    DetalhesCliente: 'Customer Details',
                    DetalhesProduto: 'Product Details',
                }
            },
            financeiro: {
                name: 'Finance',
                cards: {
                    Saldo: 'Current Balance',
                    PrevisaoReceitas: 'Expected Income',
                    PrevisaoDespesas: 'Expected Expenses',
                    SaldoPrevisto: 'Period Forecast',
                    TotalReceitas: 'Income Total',
                    TotalReceitasPrevistas: 'Expected + Realized Income',
                    TotalDespesas: 'Expenses Total',
                    DespesasPrevistas: 'Expected + Realized Expenses',
                },
                graphs: {
                    ProgressaoSaldo: 'Balance Progression',
                    ReceitasXDespesas: 'Income x Expenses',
                    ReceitasCategoria: 'Income by Category',
                    DespesasCategoria: 'Expenses by Category',
                },
                tables: {
                    Saldos: 'Balances (Today)',
                },
                texts: {
                    Previsto: 'Forecast',
                    Total: 'Total',
                    Receitas: 'Income',
                    Despesas: 'Expenses',
                    Diferenca: 'Difference',
                    Saldo: 'Balance',

                }
            },

        },

        myaccount: {
            name: 'My Account',
            texts: {
                userData: 'User Data',
                changePassword: 'Change Password',
                myData: 'My Personal Information'
            },
            fields: {
                Username: 'Username',
                Email: 'Email',
                Nome: 'Name',
                Contato: 'Contact',
                CpfCnpj: 'Document',
                InscricaoEstadual: 'Document 2',
                PaisId: 'Country',
                Endereco: 'Address',
                Numero: 'Number',
                Complemento: 'Address 2',
                Bairro: 'Neighborhood',
                EstadoId: 'State',
                CidadeId: 'City',
                CEP: 'Zip',
                Telefone1: 'Phone 1',
                Telefone2: 'Phone 2',
                Observacoes: 'Comments',
            },
        },

        changepassword: {
            name: 'Change Password',
            fields: {
                old: 'Current Password',
                new: 'New Password',
                repeat: 'Repeat the New Password',
                relogin: 'Logout in all devices',
            },
            texts: {
                oldPass: 'Current password is not matching.',
                samePass: 'Current and new passwords must be different.',
                min: 'The password must have at least 3 characters.',
                confirmation: "The confirmation doesn't match the new one.",
            },
        },
        // ################################################################################################################# SALES
        comercial: {
            name: 'Sales',

            clientes: {
                name: 'Customers',
                singular: 'Customer',
                tabs: ['Data', 'Aditional', 'Comments', 'Debits', 'Transactions', 'Invoices', 'Contracts'],
                fields: {
                    Id: 'Id',
                    Nome: 'Name',
                    NomeFantasia: 'Commercial Name',
                    Contato: 'Contact',
                    CpfCnpj: 'Document',
                    InscricaoEstadual: 'Document 2',
                    GrupoId: 'Group',
                    GrupoNome: 'Group',
                    PaisId: 'Country',
                    Endereco: 'Address',
                    Numero: 'Number',
                    Complemento: 'Address 2',
                    Bairro: 'Neighborhood',
                    EstadoId: 'State',
                    EstadoSigla: 'State',
                    CidadeId: 'City',
                    CEP: 'Zip',
                    Telefone1: 'Phone 1',
                    Telefone2: 'Phone 2',
                    Observacoes: 'Comments',
                    Bloqueado: 'Blocked',
                    UsuarioId: 'Salesman',
                    Notificar: 'Send Notifications',
                    ValorContas: {
                        name: 'Debits',
                        options: {
                            0: 'No',
                            1: 'Yes',
                        }
                    },
                    DataConta: {
                        name: 'Overdue',
                        options: {
                            0: 'No',
                            1: 'Yes',
                        }
                    },
                    MetodoPagamentoId: 'Default Payment Method',
                    RotaId: 'Sales Route',
                    FuncionarioId: 'Salesman',
                    TabelaPadraoId: 'Default Pricing Table',
                    InformacoesAdicionais: 'Additional Info',
                },
                texts: {
                    Tipo: 'This customer is also a supplier',
                    btnEnviarSenha: 'Send Link for E-commerce',
                    btnGerarLink: 'Generate Link',
                    SucessoCopiaLink: 'Link copied to clipboard.',
                    SenhaEcommerce: 'Do you realy want to generate a new password?',
                    SucessoLinkEcommerceL: 'Link generated sucessfully.',
                    ErroLinkEcommerce: 'Error generating link.',
                    EmailInvalido: 'You need to inform a valid email address.',
                    SucessoSenhaEcommerce: 'The link was sent to the customer email.',
                    ErroSenhaEcommerce: 'Error generating the e-commerce password.',
                    OutrosCreditosDebitos: 'Other Credits/Debits',
                    Outros: 'Others',
                    MensagemWhatsApp: 'Enviar Mensagem Por WhatsApp',
                },
                options: {
                    SomenteClientesVendedor: `Show only salesman's customers`
                },
                reports: {
                    CurvaABC: {
                        menuName: 'ABC Curve',
                        name: 'ABC Curve of Customers',
                        Pessoa: 'Customer',
                        Total: 'Total',
                        Porcentagem: 'Percent.',
                        Classificacao: 'Class',
                        Data: 'Date',
                        DataInicio: 'Start Date',
                        DataFinal: 'End Date',
                        ErroDataFinalInvalida: 'Invalid end date.'
                    },
                }
            },

            clientestabelaspreco: {
                name: 'Pricing Tables',
                singular: 'Pricing Table',
                fields: {
                    TabelaId: 'Pricing Table',
                    Nome: 'Pricing Table',
                }
            },

            clientesdebitos: {
                name: 'Debits',
                singular: 'Debit',
                fields: {
                    Data: 'Due Date',
                    Descricao: 'Description',
                    Valor: 'Value'
                },
                texts: {
                    Tipo: 'This customer is also a supplier',
                    VendaPendente: 'Pending Invoice',
                },
                options: {
                    SomenteClientesVendedor: `Show only salesman's customers`,
                },
            },

            clienteslancamentos: {
                name: 'Transactions',
                singular: 'Transaction',
                fields: {
                    Data: 'Date',
                    Descricao: 'Description',
                    Valor: 'Value'
                },
                texts: {
                    Tipo: 'This customer is also a supplier',
                    VendaPendente: 'Pending Invoice',
                },
                options: {
                    SomenteClientesVendedor: `Show only salesman's customers`,
                },
            },

            gruposclientes: {
                name: 'Customers Groups',
                singular: 'Customers Group',
                fields: {
                    Nome: 'Name'
                },
            },

            tabelaspreco: {
                name: 'Pricing Tables',
                singular: 'Pricing Table',
                fields: {
                    Nome: 'Name',
                    Tipo: {
                        name: 'Type',
                        options: {
                            1: 'Percentage',
                            2: 'Value',
                        }
                    },
                    Vista: 'Price',
                    Prazo: 'Foward Price',
                    VistaDisc: 'Discount',
                    PrazoDisc: 'Discount',
                }
            },

            pedidos: {
                name: 'Orders',
                singular: 'Order',
                tabs: ['Data', 'Items', 'Returns'],
                texts: {
                    Totais: 'Totals',
                    modoCatalogo: 'Catalog Mode',
                    Preco: 'Price',
                    EstoqueAtual: 'Stock',
                    PrevisaoEntrada: 'Entry Forecast',
                    PrevisaoSaida: 'Reserved',
                    SaldoEstoque: 'Balance',
                    clonar: 'Clone',
                    confirmar: 'Confirm',
                    imprimir: 'Print',
                    imprimirContasAbertos: 'Print (Open Receipts)',
                    picking: 'Order Picking',
                    pickingContasAberto: 'Order Picking (Open Receipts)',
                    MensagemClonar: 'Select the new type',
                    erroClone: 'Error cloning.',
                    sucessClone: 'Success.',
                    confirmaPedido: 'Do you realy want to confirm this order?',
                    Data: 'Date',
                    Hora: 'Time',
                    MsgCancelar: 'Do you realy want to cancel?',
                    msgItensDuplicados: 'There are duplicate items, with the same product and batch.',
                    Embalagem: 'Package',
                    Embalagens: 'Packages',
                    SelEmbalagem: 'Select Package',
                    NoPackage: 'No Package Added',
                    NoItems: 'No Items Added',
                    AddPackageMsg: 'Click in "Add Package".',

                    adicionarEmbalagem: 'Add Package',
                    TudoCerto: 'Done!',
                    ItemNaoEncontrado: 'Item not found.',
                    SemEmbalagens: 'No package added.',
                    QuantidadeInvalida: 'Invalid quantity.',
                    QuantMaiorRestante: 'Quantity is greater than the remainder.',
                    LoteNaoInformado: 'No batch selected.',
                    SalvarItensRestante: 'There are items left to pick. What do you want to do?',
                    ConfirmaSeparacao: 'Do you realy want to confirm the order picking?',
                    SalvarSomente: 'Save Only',
                    Finalizar: 'Finalize',
                    restante: 'Left:',
                    outros: 'Others Items',
                    PrecoBase: 'Base Price',
                    Entrega: 'Delivery',
                    acaoNegativos: {
                        label: 'Action for out-of-stock items',
                        options: {
                            ignore: 'Ignore',
                            new: 'New Order',
                            remove: 'Remove'
                        }
                    },
                    Devolucoes: 'Returns',
                    errors: {
                        1: 'Operation not allowed. Profit is lower than the minimum allowed for the seller.',
                        3: 'Order cannot be delivered because it contains items without batch.',
                        4: 'There are items without batch.',
                        5: 'Product with no quantity.',
                    },
                    OrderPickingTabs: {
                        0: 'Remaining',
                        1: 'Picked',
                    },

                    QuantidadeTotal: 'Quantity Total',
                    impressaoUltimosPedidosCliente: 'Last Orders (Per Customer)',
                    confirmarCondicional: 'Returning Items From Conditional Sale',
                    ConfirmarRetornoItensCondicional: 'Do you realy want to confirm the returning of those items?',
                    BuscandoVendas: 'Getting sales to print. Wait...',
                    DataPrevista: 'Estimated Date',
                    SelecionarLotesAuto: 'There are products with unselected batch(es). Do you want to select them automatically?',
                    ErroSelecaoLote: 'Error when trying to get batch(es) automatically.',
                    ErroConfirmarPedidoMin: 'You cannot confirm this order because it has a value lower than the minimum.',

                    DataPrevistaEntrega: 'Estimated Delivery',
                    DataEntrega: 'Delivery',

                },
                fields: {
                    Tipo: {
                        name: 'Operation',
                        options: {
                            'P': 'Order',
                            'V': 'Invoice',
                            'C': 'Conditional Sale'
                        }
                    },
                    Situacao: {
                        name: 'Status',
                        options: {
                            0: 'Pending',
                            1: 'Confirmed',
                            2: 'Finished',
                        },
                    },
                    Numero: 'Number',
                    PessoaId: 'Customer',
                    PessoaNome: 'Customer',
                    PessoaEndereco: 'Address',
                    Cidade: 'City',
                    Estado: 'State',
                    CEP: 'Zipcode',
                    DataHora: 'Date/Time',
                    Operacao: 'Status',
                    Descricao: 'Payment Terms',
                    Observacoes: 'Comments',
                    ObsCliente: 'Cx. Comments',
                    TotalProdutos: 'Products Total',
                    TotalDevolucao: 'Returns',
                    Desconto: 'Discount',
                    Frete: 'Shipping',
                    Seguro: 'Insurance',
                    Impostos: 'Taxes',
                    Outros: 'Others',
                    Total: 'Total',
                    FuncionarioId: 'Salesman',
                    FuncionarioNome: 'Salesman',
                    DebitosAtraso: 'Overdue Debts',
                    RotaId: 'Sales Route',
                    TabelaId: 'Pricing Table',
                    EstoqueId: 'Stock',
                    EstoqueNome: 'Stock',
                    Vencimento: 'Due Date',
                    ValorAberto: 'Amount Due',
                    QuantTotal: 'Total Qty.',
                    PesoTotal: 'Total Weight',
                    QtdItens: 'Item Count',
                    MetodoPagamentoId: 'Payment Method',
                },
                options: {
                    PodeConfirmarPedido: 'Can confirm order'
                },
                errors: {
                    0: 'There are financial transactions preventing saving. Please, cancel them and try again.'
                }
            },

            pedidos_itens: {
                name: 'Items',
                singular: 'Item',
                fields: {
                    ProdutoId: 'Product/Service',
                    EstoqueId: 'Stock',
                    Unidade: 'Unity',
                    Quantidade: 'Quantity',
                    ValorUnitario: 'Price',
                    Comissao: 'Comission',
                    TotalItem: 'Total',
                    QuantidadeTotal: 'Total Quantity',
                    Custo: 'Cost',
                    CustoItem: 'Total',
                    Lucro: 'Profit',
                },
            },

            pedidos_itens_catalogo: {
                filters: {
                    Categoria: 'Category',
                    PosicaoEstoque: {
                        name: 'Stock Status',
                        options: {
                            1: 'Positive',
                            2: 'Negative',
                            3: 'Zero',
                        }
                    },
                    Grupo: {
                        name: 'Group',
                    },
                    Promocao: {
                        name: 'Great Deal',
                    },
                    Destaque: {
                        name: 'Featured',
                    }
                },
            },


            vendas: {
                name: 'Invoices',
                singular: 'Invoice',
                tabs: ['Data', 'Items', 'Returns', 'Delivery'],
                texts: {
                    Totais: 'Totals',
                    modoCatalogo: 'Catalog Mode',
                    Preco: 'Price',
                    EstoqueAtual: 'Stock',
                    PrevisaoEntrada: 'Entry Forecast',
                    PrevisaoSaida: 'Reserved',
                    SaldoEstoque: 'Balance',
                    RecebimentoIncompleto: 'You need to add at least the order total minus the credits for that receipt.',
                    RecebimentoCrediario: 'Receivable cannot be greater than the invoice total.',

                    TotalVenda: 'Invoice Total',
                    ValorAberto: 'Amout Due',
                    Debitos: 'Debts',
                    Creditos: 'Credits',
                    TotalDevedor: 'Total',
                    TotalRecebido: 'Received',
                    GerarCredito: 'Generate credit from the difference',
                    Restante: 'Remaining',
                    CorrigirRecebimentos: 'Modify Receipts',
                    ReabrirVenda: 'Reopen Invoice',
                    recebimento: 'Receipts',
                    ReabrirVendaMsg: 'All banking transactions attached to this invoice will be voided. Do you realy want to reopen this invoice?',
                    ConfirmarReabrirVendaMsg: 'The receipt of this sale is linked with the receipt of other sales. This action will reopen all linked sales. Are you sure you want to continue?',
                    impressaoDevolucoes: 'Returns',
                    impressaoDevolucoesCusto: 'Returns (Cost)',
                    impressaoEspeciais: 'Special Products',
                    impressaoResumo: 'Product Summary',
                    impressaoPeso: '(Weights)',
                    impressaoUltimasVendasCliente: 'Last Invoices (Per Customer)',
                    ConfirmaEntrega: 'Do you confirm delivery?',
                    Assinatura: 'Signature',
                    Data: 'Date',
                    Hora: 'Hour',
                    Custo: 'Cost',
                    RemoverAssinatura: 'Remove Signature',
                    MsgRemoverAssinatura: 'Do you realy want to remove the signature on this invoice?',
                    sucessoRemoveAssinatura: 'Signature removed.',
                    Navegar: 'Navigate',
                    SelecioneLote: 'Select at least one batch.',
                    LucroBrutoComissao: 'Gross Profit/Commission',
                    Calcular: 'Calculate',
                    IgualAcimaTabela: 'Total Equal or Above Minimum Price',
                    BaixoTabela: 'Total Below Minimum Price',
                    Diferenca: 'Difference',
                    Comissao: 'Commission Amount',
                    Lucro: 'Profit',
                    LucroPercentual: 'Profit (Percentage)',
                    SincQBO: 'Sync with Quickbooks',
                    impressaoListaEntrega: 'Delivery List',
                    BtnAtualizarCustos: 'Update Products Cost',
                    custosAtualizados: 'Updated products cost. Save to recalculate the profit.',
                    impressaoProdutosVendidos: 'Products Sold',
                    CondPagto: 'Payment',
                    erroComp: 'Error calculating differences.',
                    Entregador: 'Delivery Person',
                    exportListCliente: 'Export List (Customer Info)',
                    alteracoes: 'Change Log',
                },
                fields: {
                    Tipo: {
                        name: 'Operation',
                        options: {
                            'P': 'Order',
                            'V': 'Invoice',
                            'C': 'Conditional Sale'
                        }
                    },
                    Numero: 'Number',
                    PessoaId: 'Customer',
                    PessoaNome: 'Customer',
                    PessoaEndereco: 'Address',
                    Cidade: 'City',
                    Estado: 'State',
                    CEP: 'Zipcode',
                    DataHora: 'Date/Time',
                    Operacao: 'Status',
                    Descricao: 'Payment Terms',
                    Observacoes: 'Comments',
                    TotalProdutos: 'Products Total',
                    Desconto: 'Discount',
                    Frete: 'Shipping',
                    Seguro: 'Insurance',
                    Impostos: 'Taxes',
                    Outros: 'Others',
                    Total: 'Total',
                    EstoqueNome: 'Stock',
                    Situacao: {
                        name: 'Status',
                        options: {
                            0: 'For Delivery',
                            1: 'Pending',
                            2: 'Paid',
                            3: 'Partial Paid',
                        },
                    },
                    TotalDevolucao: 'Returns Total',
                    FuncionarioId: 'Salesman',
                    FuncionarioNome: 'Salesman',
                    RotaId: 'Sales Route',
                    TabelaId: 'Pricing Table',
                    EstoqueId: 'Stock',
                    FuncionarioAdicionalId: 'Deliveryman',
                    Vencimento: 'Due Date',
                    ValorAberto: 'Amount Due',
                    DataHoraAdicional: 'Delivery Date',
                    FuncionarioAdicionalNome: 'Deliveryman',
                    MetodoPagamentoId: 'Payment Method',
                    PesoTotal: 'Total Weight',
                    QtdItens: 'Item Count',
                },
                errors: {
                    1: 'For receivable, you should add at least the invoice amount.'
                }
            },

            vendas_itens: {
                name: 'Items',
                singular: 'Item',
                fields: {
                    ProdutoId: 'Product/Service',
                    EstoqueId: 'Stock',
                    Unidade: 'Unity',
                    UnidadeTrib: 'Unity Item',
                    Quantidade: 'Quantity',
                    QuantidadeTrib: 'Quantity Package',
                    ValorUnitario: 'Price',
                    ValorUnitarioTrib: 'Price/Item',
                    TotalItem: 'Total',
                    Lote: 'Batch',
                    Validade: 'Expiration',
                    ValidadeAbrv: 'Exp.',
                    Peso: 'Weight',
                    Restante: 'Remaining',
                    PrevisaoSaida: 'Reserved',
                    Disponivel: 'Available'
                },
                texts: {
                    SelecionaLote: 'Select Batch',
                    NenhumLote: 'No batches found.',


                }
            },

            recebimentos: {
                name: 'Open Receipts',
                singular: 'Receipt',
                fields: {
                    Descricao: 'Description',
                    Data: 'Due Date',
                    Valor: 'Amount',
                    Total: 'Total',
                }
            },
            recebimentos_itens: {
                name: 'Receipts',
                singular: 'Receipt',
                fields: {
                    MetodoId: 'Method',
                    Observacoes: 'Notes',
                    Valor: 'Value'
                }
            },
            comissoes: {
                name: 'Commissions',
                singular: 'Commission',
                fields: {
                    Tipo: {
                        name: 'Type',
                        options: {
                            1: 'All Invoices',
                            2: 'All Received Invoices',
                            3: 'Invoices of Period',
                            4: 'Received Invoices of Period',
                            5: 'Received Invoices Until...',
                        }
                    },
                    FuncionarioId: 'Salesman',
                    FuncionarioNome: 'Salesman',
                    DataInicio: 'Start Date',
                    DataFim: 'End Date',
                    DataHora: 'Date/Time',
                    TotalVendas: 'Invoices Total',
                    TotalDiff: 'Disc./Diff. Total',
                    TotalComissao: 'Commission Total',
                    Observacoes: 'Notes',
                    Periodo: 'Sales Period',
                },
                texts: {
                    Pesquisar: 'Search',
                    TotalIgualAcimaTabela: 'Total equal to or above minimum price:',
                    TotalBaixoTabela: 'Total below mininum price:',
                }
            },
            comissoes_vendas: {
                name: 'Vendas',
                singular: 'Venda',
                fields: {
                    Numero: 'Number',
                    PessoaNome: 'Customer',
                    DataHora: 'Date',
                    TotalVenda: 'Total',
                    Situacao: {
                        name: 'Status',
                        options: {
                            0: 'For Delivery',
                            1: 'Pending',
                            2: 'Finished',
                        },
                    },
                    Comissao: 'Comission',
                    Desconto: 'Disc.',
                    Diferenca: 'Diff.'
                }
            },

            alteracoes_vendas: {
                fields: {
                    UsuarioNome: 'User',
                    _createdAt: 'Date/Time',
                    Situacao: {
                        name: 'Status',
                        options: {
                            0: 'For Delivery',
                            1: 'Pending',
                            2: 'Finished',
                        },
                    },
                    Itens: 'Items',
                    Quantidade: 'Quantity',
                    Total: 'Total'
                }
            },
            alteracoes_pedidos: {
                fields: {
                    UsuarioNome: 'User',
                    _createdAt: 'Date/Time',
                    Situacao: {
                        name: 'Status',
                        options: {
                            0: 'Pending',
                            1: 'Confirmed',
                            2: 'Finished',
                        },
                    },
                    Itens: 'Items',
                    Quantidade: 'Quantity',
                    Total: 'Total'
                }
            }
        },

        // ############################################################################################################## LOGISTICS

        logistica: {
            name: 'Logistics',
            rotasvenda: {
                name: 'Sales Routes',
                singular: 'Sales Route',
                fields: {
                    Nome: 'Name',
                    Clientes: 'Customers',
                    PessoaId: 'Customer',
                    PaisId: 'Country',
                    EstadoId: 'State',
                    FuncionarioNome: 'Salesman',
                    FuncionarioId: 'Salesman',
                },
                texts: {
                    AdicionarClientes: 'Add Customers',
                    Visualizar: 'View Customer'
                },
                options: {
                    SomenteRotasVendedor: 'Only salesman`s routes',
                },
                tabs: {
                    0: 'Sales',
                    1: 'Delivery'
                }
            },

            pacotes: {
                name: 'Packaging',
                singular: 'Package',
                fields: {
                    Nome: 'Name',
                    Propria: 'Own Package'
                },
            },
        },

        producao: {
            name: 'Production',

            producoes: {
                name: 'Productions',
                singular: 'Production',
                tabs: ['Data', 'Production Inputs'],
                fields: {
                    Numero: 'Sequential',
                    DataHora: 'Date/Time',
                    Descricao: 'Description',
                    Operacao: {
                        name: 'Operation',
                        options: {
                            'O': 'Production Order',
                            'P': 'Prodcution',
                            'R': 'Partial Production',
                        },
                    },
                    EstoqueId: 'Stock',
                    EstoqueNome: 'Stock',
                    ProdutoId: 'Product',
                    Quantidade: 'Quantity',
                    Unidade: 'Unity',
                    QuantidadeProduzida: 'Already Produced',
                    QuantidadeRestante: 'Remaining',
                    Observacoes: 'Batch',
                    DataHoraAdicional: 'Expiration',
                    EntregaId: 'Production Group',
                    Info: 'Production Info',

                },
                texts: {
                    DadosProduto: 'Product Info',
                    SelLote: 'Select a Batch'
                }
            },

            grupos: {
                name: 'Production Groups',
                singular: 'Production Group',
                fields: {
                    Nome: 'Name',
                    MascaraLote: 'Batch Mask',
                    Sequencial: 'Current Sequential Number',
                },
                texts: {
                    Explicacao1: 'P = Product ID; T or R = Traceabillity Code; D = Day; M = Month; Y or A = Year; S = Sequential Number; " " = Text (Between double quotes)',
                    Explicacao2: 'Example: "L"YYYYMMSSSSS will give you the batch L20240300013, with year 2024, month 03 and the sequential number 13.',
                    Explicacao3: 'The generated batch can only contains 20 characters max.',
                }
            },
        },

        // ################################################################################################################## STOCK

        estoque: {
            name: 'Stock',
            compras: {
                name: 'Purchases',
                singular: 'Purchase',
                tabs: ['Data', 'Items', 'Order Diff.'],
                texts: {
                    Totais: 'Totals',
                    modoCatalogo: 'Catalog Mode',
                    Preco: 'Price',
                    EstoqueAtual: 'Stock',
                    PrevisaoEntrada: 'Entry Forecast',
                    PrevisaoSaida: 'Reserved',
                    SaldoEstoque: 'Balance',
                    analizeConfirmation: 'Do you confirm changes to products?',
                    analizar: 'Analyze Products',
                    CustoAnterior: 'Registred Cost',
                    Compra: 'Purchase',
                    CustoVariavel: 'Variable Cost',
                    Custo: 'Cost',
                    PrecoAnterior: 'Registred Price',
                    LucroAnterior: 'Registred Profit',
                    PrecoVenda: 'Price',
                    Lucro: 'Profit',
                    DiferencaCusto: 'Cost Diff.',
                    DiferencaVenda: 'Price Diff.',
                    Comissao: 'Commission',
                    Data: 'Date',
                    Hora: 'Time',
                    clonar: 'Clone',
                    checking: 'Item Checking',
                    CodigoBarras: 'Barcode',
                    ReprocessandoEstoque: 'Reprocessing Stock',
                    Reprocessando: 'Reprocessing Stock... Please wait.',
                    confirmar: 'Confirm Order',
                    Operacao: {
                        label: 'Action',
                        options: {
                            copiar: 'Copy items',
                            bipar: 'Add items manually'
                        }
                    },
                    compOk: 'Everything ok!',
                    ProdutosComprados: 'Purchased Products',
                    ExportarProdutosComprados: 'Export Purchased Products',

                },
                fields: {
                    Numero: 'Number',
                    PessoaId: 'Supplier',
                    PessoaNome: 'Supplier',
                    DataHora: 'Date/Time',
                    Operacao: {
                        name: 'Operation',
                        options: {
                            'E': 'Purchase',
                            'C': 'Order',
                            'W': 'Proposal',
                            'x': 'Confirmed Order'
                        },
                    },
                    TotalProdutos: 'Products Total',
                    Desconto: 'Discount',
                    Frete: 'Shipping',
                    Seguro: 'Insurance',
                    Impostos: 'Taxes',
                    Outros: 'Others',
                    Total: 'Total',
                    TotalItem: 'Item Total',
                    EstoqueId: 'Stock',
                    EstoqueNome: 'Stock',
                    Grupo: 'Group',
                    Assinado: 'Analyzed',
                    QtdItens: 'Item Qty.',
                    PesoTotal: 'Gross Weight',

                },
                reports: {
                    EtiquetaProduto: {
                        name: 'Labels/Tags'
                    },

                    imprimirEtiquetas: 'Print Labels/Tags',
                    imprimir: 'Print',
                    imprimirSomenteQuantidade: 'Print (Only Quantities)',
                    imprimirPesos: 'Print Weights',
                    imprimirPesosQtd: 'Print Weights (Only Quantities)',
                    exportarCompra: 'Export'

                }
            },

            compras_itens: {
                name: 'Items',
                singular: 'Item',
                fields: {
                    ProdutoId: 'Product',
                    EstoqueId: 'Stock',
                    Unidade: 'Unity',
                    Quantidade: 'Quantity',
                    ValorUnitario: 'Price',
                    TotalItem: 'Total',
                    Lote: 'Batch',
                    Fabricacao: 'Manufacturing',
                    Validade: 'Expiration',
                    PesoBruto: 'Gross Weight'
                },
            },
            compras_itens_catalogo: {
                filters: {
                    Categoria: 'Category',
                    PosicaoEstoque: {
                        name: 'Stock Status',
                        options: {
                            1: 'Positive',
                            2: 'Negative',
                            3: 'Zero',
                        }
                    },
                    Grupo: {
                        name: 'Group',
                    },
                    Promocao: {
                        name: 'Great Deal',
                    },
                    Destaque: {
                        name: 'Featured',
                    }
                },
            },

            inventarios: {
                name: 'Inventories',
                singular: 'Inventory',
                fields: {
                    Descricao: 'Description',
                    DataHora: 'Date/Time',
                    Operacao: {
                        name: 'Operation',
                        options: {
                            'I': 'Inventory',
                            'N': 'Stock Counting'
                        },
                    },
                    TotalProdutos: 'Products Total',
                    Estoque: 'Stock',
                    EstoqueId: 'Stock',
                    EstoqueNome: 'Stock',
                    Total: 'Total',
                },
                texts: {
                    SincQBO: 'Sync Products with Quickbooks',
                    SincQBOSucess: 'Products will sync on demand. This may take some time.',
                }
            },

            perdas: {
                name: 'Losses/Consumption',
                singular: 'Loss/Consumption',
                fields: {
                    Info: 'Losses/Consumption Info',
                    Descricao: 'Description',
                    DataHora: 'Date/Time',
                    Tipo: {
                        name: 'Operation',
                        options: {
                            'L': 'Loss',
                            'U': 'Consumption'
                        },
                    },
                    TotalProdutos: 'Products Total',
                    EntregaId: 'Reason',
                    Observacoes: 'Comments',
                    Estoque: 'Stock',
                    EstoqueId: 'Stock',
                    EstoqueNome: 'Stock',
                    Total: 'Total',
                },
            },

            transferencias: {
                name: 'Transfers',
                singular: 'Transfer',
                fields: {
                    Descricao: 'Description',
                    DataHora: 'Date/Time',
                    EstoqueId: 'Destination',
                    EstoqueNome: 'Destination',

                    EstoqueOrigemId: 'Source',
                    EstoqueOrigemNome: 'Source',
                    TotalProdutos: 'Products Total',
                    Total: 'Total',
                    Info: 'Transfer Info'
                },
                texts: {
                    MesmaOrigemDestino: 'Source and Destination cannot be the same.'
                }
            },

            produtos: {
                name: 'Products',
                singular: 'Product',
                tabs: ['Data', 'Aditional', 'Production', 'Pricing Table', 'Stock', 'Images', 'Commissions'],
                fields: {
                    Tipo: {
                        name: 'Type',
                        options: ['', 'Product', 'Service', 'Production Input/Raw Material', 'Consumer Product', 'Patrimony'],
                    },
                    Descricao: 'Description',
                    DescricaoCompleta: 'Complete Description',
                    CodigoBarras: 'Barcode',
                    SKU: 'SKU',
                    Fabricante: 'Manufacturer',
                    GrupoId: 'Group',
                    Grupo: 'Group',

                    Unidade: 'Unity',
                    QuantidadePacote: 'Package Quantity',
                    UnidadeItem: 'Item Unity',
                    PesoBruto: 'Gross Weight',
                    PesoLiquido: 'Net Weight',
                    Altura: 'Height',
                    Largura: 'Width',
                    Comprimento: 'Length',
                    Compra: 'Purchase Price',
                    CustoFrete: 'Purchase Shipping Cost',
                    CustoVariavel: 'Variable Cost',
                    CustoOutros: 'Other Costs',
                    TipoCusto: {
                        name: 'Cost Type',
                        options: ['', 'Purchase Price', 'Composition Cost']
                    },
                    Custo: 'Cost',
                    CustoMedio: 'Average Cost',
                    TipoLucro: {
                        name: 'Profit Calculation Type',
                        options: ['', 'Simple', 'Gross Margin', 'Manual']
                    },
                    FatorMarkup: 'Markup Factor',
                    Lucro: 'Profit',
                    PrecoVista: 'Price',
                    PrecoPrazo: 'Foward Price',
                    PrecoMinimo: 'Minimum Price',
                    Comissao: 'Commission',
                    CicloCompra: 'Purchase Cycle',
                    EstoqueMinimo: 'Minimum Stock',
                    EstoqueReposicao: 'Replacement Stock',
                    EstoqueAtual: 'Stock',
                    PrevisaoEntrada: 'Entry Forecast',
                    PrevisaoSaida: 'Reserved',
                    Disponivel: 'Available',
                    Ativo: 'Active',
                    Destaque: 'Featured',
                    Especial: 'Special',
                    Fracionado: 'Fractioned',
                    ControleLote: 'Batch Control',
                    SeparacaoPeso: 'Order Pick by Weight',
                    ConversaoPeso: {
                        name: 'Weight Conversion (Order Picking)',
                        options: {
                            1: 'No Conversion',
                            2.20462: 'KG to LB',
                            0.45359: 'LB to KG',
                        }
                    },
                    DecimaisCodBarras: 'Barcode Decimals',
                    PrecoSugerido: 'Suggested Price',
                    LucroSugerido: 'Suggested Profit',

                    TipoProducao: {
                        name: 'Production Type',
                        options: ['', 'Third Party', 'Own']
                    },

                    CodigoRastreio: 'Traceability Code',
                    DiasValidade: 'Expiring Days',
                    DiasProducao: 'Production Time',
                    TextoImpressao: 'Print Text',

                },
                filters: {
                    Categoria: 'Category',
                    Disponivel: 'Available',
                    PosicaoEstoque: {
                        name: 'Stock Status',
                        options: {
                            1: 'Positive',
                            2: 'Negative',
                            3: 'Zero',
                        }
                    },
                    Imagens: {
                        name: 'Images',
                        options: {
                            0: 'Without Images',
                            1: 'With Images',
                        }
                    },
                    CodigoBarras: {
                        name: 'Barcode',
                        options: {
                            0: 'Without Barcode',
                            1: 'With Barcode',
                        }
                    },
                    Promocao: {
                        name: 'Great Deal',
                    },
                },
                texts: {
                    PrintMoviment: 'Print Stock Movement',
                    PrintOpenOrders: 'Print Open Orders',
                    ReprocessarEstoque: 'Reprocess Stock',
                    MsgReprocessaEstoqueConfirmacao: 'Do you really want to reprocess the stock?',
                    MsgReprocessaEstoqueSucesso: 'Stock successfully reprocessed.',
                    MsgReprocessaEstoqueErro: 'Error reprocessing stock.',
                    PosicaoEstoque: 'Stock Position',
                    CodigosBarras: 'Barcodes',
                    AdicionarCodBarras: 'Add Barcode',
                    Validades: 'Expirations',
                    CloneDe: 'Clone of ',
                    ReprocessandoEstoque: 'Reprocessing stock',
                    Reprocessando: 'Reprocessing stock... wait.'
                },
                errors: {
                    MateriaPrimaAdd: 'Input/Raw Material already in composition.'
                },
                reports: {
                    Catalogo: {
                        name: 'Product Catalog',
                    },
                    CurvaABC: {
                        menuName: 'ABC Curve',
                        name: 'ABC Curve of Products',
                        Produto: 'Product',
                        Total: 'Total',
                        Porcentagem: 'Percent.',
                        Classificacao: 'Class',
                        Data: 'Date',
                        DataInicio: 'Start Date',
                        DataFinal: 'End Date',
                        ErroDataFinalInvalida: 'Invalid end date.'
                    },
                    MovimentoEstoque: {
                        ReportName: 'Stock Movement',
                        Numero: 'Number',
                        Operacao: 'Operation',
                        Data: 'Date',
                        PessoaNome: 'Person/Description',
                        ValorUnitario: 'Price',
                        SaldoInicial: 'Prev. Bal.',
                        Quantidade: 'Quantity',
                        Saldo: 'Balance',
                        Entradas: 'Entries',
                        Saidas: 'Withdrawal',
                    },

                    PedidosAbertos: {
                        ReportName: 'Open Orders',
                        Numero: 'Number',
                        Operacao: 'Operation',
                        Data: 'Date',
                        PessoaNome: 'Customer/Supplier',
                        SaldoInicial: 'Prev. Bal.',
                        Quantidade: 'Quantity',
                        Saldo: 'Balance',
                        Entradas: 'Entries',
                        Saidas: 'Withdrawal',
                    },

                    EtiquetaProduto: {
                        name: 'Product Label',
                        error: 'Error generating the Label/Tag.',
                    },
                    SugestaoCompra: {
                        name: 'Purchase Suggestion',
                        Tipo: {
                            name: 'Based on',
                            options: {
                                0: 'Calculated Suggestion',
                                1: 'Based on Minimum Stock',
                                2: 'Both, Calculated and Registred',
                                3: 'All Products'
                            }
                        },
                        Destino: {
                            name: 'Destination',
                            options: {
                                0: 'Report',
                                1: 'Export (.xlsx)'
                            }
                        },
                        Unidade: 'Unity',
                        VendasPeriodo: 'Sales',
                        Estoque: 'Stock',
                        Minimo: "Min.",
                        ParaCompra: 'To Purchase',

                    },
                    TabelaPrecos: {
                        name: 'Pricing Table',
                        TabelaId: 'Pricing Table',
                        EstoqueId: 'Stock',
                        Tipo: {
                            name: 'Print Layout',
                            options: {
                                1: 'List with Images',
                                2: 'Large Items',
                            }
                        }

                    },

                    RastreioLote: {
                        name: 'Batch Tracking',
                        NaoEncontrado: 'No movement found.',
                        Operacao: 'Operation',
                        Pessoa: 'Person/Description',
                        DataHora: 'Date',
                        Unidade: 'Unity',
                        Quantidade: 'Quantity',
                        Insumo: 'Input/Raw Material',
                        Lote: 'Batch',

                    },
                }
            },

            produtos_tabelas: {
                name: 'Pricing Table',
                fields: {
                    Nome: 'Pricing Table',
                    Tipo: 'Type',
                    Vista: 'Price',
                    Prazo: 'Foward Price',
                    PrecoFinal: 'Result Price',
                },
            },

            produtos_comissoes: {
                name: 'Commissions',
                fields: {
                    Nome: 'Salesman',
                    Comissao: 'Commission',
                    Reducao: 'Reduction',
                },
            },



            produtos_categorias: {
                name: 'Categories',
                singular: 'Category',
                fields: {
                    Nome: 'Category',
                    CategoriaId: 'Category',
                }
            },

            grupos_categorias: {
                name: 'Categories',
                singular: 'Category',
                fields: {
                    Nome: 'Category',
                    CategoriaId: 'Category',
                }
            },

            produtos_estoques: {
                name: 'Stocks',
                fields: {
                    Nome: 'Stock Name',
                    EstoqueAtual: 'Current',
                    PrevisaoEntrada: 'Entry Forecast',
                    PrevisaoSaida: 'Reserved',
                    Disponivel: 'Available',
                },
            },

            produtos_compras: {
                name: 'Last Purchases',
                fields: {
                    Nome: 'Supplier',
                    Id: '#Purchase',
                    Numero: 'Number',
                    DataHora: 'Date',
                    Quantidade: 'Quantity',
                    ValorUnitario: 'Price',
                    TotalItem: 'Total'
                },
            },

            produtos_lotes: {
                name: 'Batches',
                Singular: 'Batch',
                fields: {
                    Lote: 'Batch',
                    Fabricacao: 'Manufacturing Date',
                    Validade: 'Expiration',
                    EstoqueAtual: 'Current',
                    PrevisaoEntrada: 'Entry Forecast',
                    PrevisaoSaida: 'Reserved',
                    Disponivel: 'Available',
                },
            },

            produtos_composicao: {
                name: 'Composition',
                Singular: 'Input/Raw Material',
                fields: {
                    ComposicaoId: 'Input/Raw Material',
                    Descricao: 'Input/Raw Material Name',
                    Custo: 'Cost',
                    CustoTotal: 'Total Cost',
                    Unidade: 'Unity',
                    Quantidade: 'Quantity',
                    Relativo: 'Relative'
                },
            },

            fornecedores: {
                name: 'Suppliers',
                singular: 'Supplier',
                fields: {
                    Id: 'Id',
                    Nome: 'Name',
                    NomeFantasia: 'Commercial Name',
                    Contato: 'Contact',
                    CpfCnpj: 'Document',
                    InscricaoEstadual: 'Document 2',
                    PaisId: 'Country',
                    Endereco: 'Address',
                    Numero: 'Number',
                    Complemento: 'Address 2',
                    Bairro: 'Neighborhood',
                    EstadoId: 'State',
                    CidadeId: 'City',
                    CEP: 'Zip',
                    Telefone1: 'Phone 1',
                    Telefone2: 'Phone 2',
                    Observacoes: 'Comments',
                    Bloqueado: 'Blocked',
                },
                texts: {
                    Tipo: 'This supplier is also a customer'
                },
            },


            grupos: {
                name: 'Groups',
                singular: 'Group',
                fields: {
                    Nome: 'Name',
                }
            },

            categorias: {
                name: 'Categories',
                singular: 'Category',
                fields: {
                    CategoriaId: 'Category',
                    Nome: 'Name',
                }
            },

            estoques: {
                name: 'Stocks',
                singular: 'Stock',
                fields: {
                    Nome: 'Name',
                    Ativo: 'Active',
                    Operacao: {
                        optionsTipo: ['S', 'E'],
                        options: {
                            'S': 'Withdrawal',
                            'P': 'Order',
                            'p': 'Confirmed Order',
                            'E': 'Entry',
                            'C': 'Purchase Order',
                            'I': 'Inventory',
                            'N': 'Stock Counting',
                            'D': 'Return',
                            'T': 'Stock Transfer',
                        },
                    },
                    Tipo: {
                        options: {
                            'C': 'Purchase',
                            'N': 'Inventory/Stock Counting',
                            'V': 'Invoice',
                            'P': 'Order',
                            'D': 'Return',
                            'T': 'Stock Transfer',
                            'L': 'Loss',
                            'U': 'Use/Consumption',
                            'R': 'Production',
                            'RS': 'Production (Material)'
                        }
                    },
                },
                errors: {
                    1: 'Cannot delete this stock. There are products in it.',
                    2: 'Cannot delete this stock. There are reserved products from it.',
                    3: 'Cannot delete this stock. There are Entry Forecast products in it.',
                    4: 'Cannot delete this stock because it is the default stock.',
                },
            },

            alteracoes_compras: {
                fields: {
                    UsuarioNome: 'User',
                    _createdAt: 'Date/Time',
                    Operacao: {
                        name: 'Operation',
                        options: {
                            'E': 'Purchase',
                            'C': 'Order',
                            'W': 'Proposal',
                            'x': 'Confirmed Order'
                        },
                    },
                    Itens: 'Items',
                    Quantidade: 'Quantity',
                    Total: 'Total'
                }
            },
            alteracoes_inventarios: {
                fields: {
                    UsuarioNome: 'User',
                    _createdAt: 'Date/Time',
                    Operacao: {
                        name: 'Operation',
                        options: {
                            'I': 'Inventory',
                            'N': 'Stock Counting',
                        },
                    },
                    Itens: 'Items',
                    Quantidade: 'Quantity',
                    Total: 'Total'
                }
            },

            alteracoes_transferencias: {
                fields: {
                    UsuarioNome: 'User',
                    _createdAt: 'Date/Time',
                    Operacao: {
                        name: 'Operation',
                        options: {
                            'I': 'Transfer',
                        },
                    },
                    Itens: 'Items',
                    Quantidade: 'Quantity',
                    Total: 'Total'
                }
            },

            alteracoes_perdas: {
                fields: {
                    UsuarioNome: 'User',
                    _createdAt: 'Date/Time',
                    Operacao: 'Operation',
                    Tipo: {
                        name: 'Operation',
                        options: {
                            'L': 'Loss',
                            'U': 'Consumption'
                        },
                    },
                    Itens: 'Items',
                    Quantidade: 'Quantity',
                    Total: 'Total'
                }
            },
        },

        helpdesk: {
            name: 'Help Desk',

        },

        // ######################################################################################################################################## BANKING

        financeiro: {
            name: 'Banking',

            lancamentos: {
                name: 'Transactions',
                singular: 'Transaction',
                fields: {
                    Tipo: {
                        name: '',
                        options: ['Income', 'Expense']
                    },
                    Data: 'Date',
                    Descricao: 'Description',
                    Valor: 'Value',
                    Realizado: 'Value',
                    Saldo: 'Balance',
                    ContaId: 'Account',
                    MetodoId: 'Payment Method',
                    CategoriaId: 'Category',
                    PessoaId: 'Person',
                    Pessoa: 'Person',
                    Recorrente: 'Recurrent',
                    TipoRecorrencia: {
                        name: 'Type',
                        options: ['', 'Weekly', 'Fortnightly', 'Monthly', 'Bimonthly', 'Quarterly', 'Semiannual', 'Yearly'],
                    },
                    QuantidadeParcelas: 'Installments',
                    AtualizaVinculados: 'Update Linked',
                    AcaoVinculados: {
                        name: 'Action',
                        options: ['', 'Update Later Opens Only', 'Update Only Open', 'Update All Later', 'Update All']
                    },
                    Transferencia: 'Transfers',
                    NossoNumero: 'Our Number',
                    LinhaDigitavel: 'Digitable Line',
                    SituacaoBoleto: {
                        name: 'Bill Status',
                        options: ['', 'Not Issued', 'Waiting Payment', 'Paid'],
                    },
                    Credito: 'Generate Credit',

                },
                options: {
                    PodeQuitarConta: 'Can make payment/receipt'
                },
                filters: {
                    Situacao: {
                        name: 'Status',
                        options: ["Paid", "Received", "To Pay", "To Receive",]
                    }
                },
                texts: {
                    SaldoAnterior: 'Previous Balance:',
                    Saldo: 'Balance:',
                    ClassificacaoLancamento: 'Transaction Classification',
                    Quitacao: 'Receipt/Payment',
                    erroQuitacao: 'Error performing receipt/payment.',
                    sucessoQuitacao: 'Receipt/payment made successfully.',
                    PrintList: 'Print List',
                    QuitarSelecao: 'Receive/Pay Selected',
                    NenhumRegistroSelecionado: 'No records selected.',
                    erroContaJaPagaRecebida: 'It is not possible to receive/pay for a record already paid/received.',
                    Transferencia: 'Transfer',
                    TransfDescricao: 'Description (optional)',
                    ContaOrigem: 'Source Account',
                    ContaDestino: 'Destination Account',
                    Pago: 'Paid',
                    Agendado: 'Schedule Payment',
                    Cobranca: 'Payment Collection',
                    DadosBoleto: 'Bill Data',
                    SucessoCopiaLinhaDigitavel: 'Digitable Line copied.',
                    BaixarBoleto: 'Void Bill',

                    PerguntaEmitirBoleto: 'Do you realy want to issue this bill?',
                    PerguntaBaixarBoleto: 'Do you realy want do void this bill?',

                    EmitindoBoleto: 'Issuing the bill',
                    EmitindoBoletoMsg: 'Issuing the bill. Wait...',
                    erroEmitindoBoleto: 'Error issuing the bill.',
                    sucessoEmitindoBoleto: 'Bill issued successfully.',

                    BaixandoBoleto: 'Voiding the bill',
                    BaixandoBoletoMsg: 'Voiding the bill. Wait...',
                    erroBaixaBoleto: 'Error voiding the bill.',
                    sucessoBaixaBoleto: 'Bill voided successfully.',

                    EnviarEmail: 'Send E-mail',
                    VerificarSituacao: 'Check Status',
                }
            },

            pessoas: {
                name: 'Persons',
                singular: 'Person',
            },

            metodospagamento: {
                name: 'Payment Methods',
                singular: 'Payment Method',
                fields: {
                    Id: 'Id',
                    Nome: 'Name',
                    Tipo: 'Type',
                    Crediario: 'To Receive',
                    Prazo: 'Term (Days)',
                    QuantidadeParcelas: 'Installments',
                    ContaId: 'Account',
                    Boleto: "Bank Slip",
                }
            },

            contas: {
                name: 'Accounts',
                singular: 'Account',
                fields: {
                    Nome: 'Name',
                    Tipo: 'Type',
                    VisaoGeral: 'Dashboard',
                    SaldoHoje: 'Today\'s Balance',
                    PrevisaoMes: 'Month Forecast',
                    Ativa: 'Active'
                },
                texts: {
                    ReprocessaCaixa: 'Reprocess account balance'
                },
            },

            categorias: {
                name: 'Categories',
                singular: 'Category',
                fields: {
                    Nome: 'Name',
                    Cor: 'Color',
                    Icone: 'Icon',
                }
            },

            cobrancas: {
                name: 'Collector',
                singular: 'Collector',
                fields: {
                    Descricao: 'Description',
                    Tipo: {
                        name: 'Kind',
                        options: {
                            'R': 'Receivable',
                            'B': 'Titles',
                            'T': 'All',
                        }
                    },
                    Dias: 'Due',
                    Conteudo: 'Content'

                }
            }
        },

        // ############################################################################################################ SETTINGS
        configuracoes: {
            name: 'Settings',

            etiquetas: {
                name: 'Labels and Tags',
                singular: 'Label/Tag',
                fields: {
                    Nome: 'Name',
                    Tipo: {
                        name: 'Kind',
                        options: {
                            'produto': 'Products'
                        }
                    },
                    Largura: 'Width',
                    Altura: 'Height',
                    Colunas: 'Columns',
                    Margem: 'Margins',
                    Tamanho: 'Size',
                    Orientacao: {
                        name: 'Orientation',
                        options: {
                            1: 'Normal (Portrait)',
                            2: 'Lying Down (Landscape)'
                        }
                    },
                    Zoom: 'Zoom',
                    Conteudo: 'Content',
                    Valor: 'Value',
                    TamanhoFonte: 'Font Size',
                    Negrito: 'Bold',
                    LarguraMax: 'Max Width',
                    AlturaMax: 'Max Height',
                    EsconderValor: 'Hide Value',
                    Variavel: 'Variable',

                },
                texts: {
                    Vazio: 'empty',
                    AdicionaElemento: 'Element',
                    Testar: 'Test',
                    Texto: 'Text',
                    CodBarras: 'Barcode',
                    PropTexto: 'Text Properties',
                    PropCodBar: 'Barcode Properties',
                    qrCode: 'QR Code',
                    PropQrCode: 'QR Code Properties'
                },
                variables:
                {
                    produto: {
                        Id: 'Id',
                        CodigoBarras: 'Manufacturer Barcode',
                        Descricao: 'Description',
                        SKU: 'SKU',
                        Fabricante: 'Manufacturer',
                        Grupo: 'Group',
                        Unidade: 'Unity',
                        QuantidadePacote: 'Package Quantity',
                        UnidadeItem: 'Item Unity',
                        PesoBruto: 'Gross Weight',
                        PesoLiquido: 'Net Weight',
                        PrecoVista: 'Price',
                        PrecoPrazo: 'Foward Price',
                        PrecoPrazo_$: 'Installment Amount',
                        PrecoSugerido: 'Suggested Price',
                        Lote: 'Batch *',
                        Fabricacao: 'Manufacturing Date *',
                        Validade: 'Expiration *',
                    }
                },
                dialogs: {
                    fields: {
                        Modelo: 'Model',
                        Copias: 'Copies',
                        Pular: 'Skip Labels',
                        ImprimirBordas: 'Print Borders',
                    },
                },
                formats: {
                    produto: {
                        PesoBruto: {
                            kind: 'float',
                            format: ',0.000'
                        },
                        PesoLiquido: {
                            kind: 'float',
                            format: ',0.000'
                        },
                        PrecoVista: {
                            kind: 'float'
                        },
                        PrecoPrazo: {
                            kind: 'float',
                        },
                        PrecoPrazo_$: {
                            kind: 'float',
                        },
                        PrecoSugerido: {
                            kind: 'float',
                        },
                        Fabricacao: {
                            kind: 'date'
                        },
                        Validade: {
                            kind: 'date'
                        },
                    }
                },
            },

            funcionarios: {
                name: 'Employees',
                singular: 'Employee',
                fields: {
                    Id: 'Id',
                    Nome: 'Name',
                    Contato: 'Contact',
                    CpfCnpj: 'Document',
                    InscricaoEstadual: 'Document 2',
                    PaisId: 'Country',
                    Endereco: 'Address',
                    Numero: 'Number',
                    Complemento: 'Address 2',
                    Bairro: 'Neighborhood',
                    EstadoId: 'State',
                    CidadeId: 'City',
                    CEP: 'Zip',
                    Telefone1: 'Phone 1',
                    Telefone2: 'Phone 2',
                    Observacoes: 'Comments',
                    EstoquePadraoId: 'Default Stock',
                    Tag: 'This employee is a delivery person',
                    Comissao: 'Commission',
                    Bloqueado: 'Percentage commission of the employee with priority',
                },
                texts: {
                    Tipo: 'This employee is a sellsman',
                    Reducao: 'Reduction (Below Pricing Table)',
                    LucroMinimo: 'Minimum Profit',
                    ComissoesNovo: '*You have to save before adding commissions by category.'
                },
            },

            funcionarios_comissoes: {
                name: 'Commissions per Category',
                singular: 'Commission',
                fields: {
                    CategoriaId: 'Category',
                    Comissao: 'Commission',
                    Reducao: 'Reduction'
                }
            },

            usuarios: {
                name: 'Users',
                singular: 'User',
                fields: {
                    Id: 'Id',
                    Nome: 'Username',
                    Tag: 'Employee',
                    FuncionarioNome: 'Employee',
                    Username: 'Username',
                    IsAdmin: 'Administrator',
                },
                texts: {
                    permissions: 'Permissions',
                    options: 'Options',
                    ResetPassword: 'Reset password',
                    ResetPasswordQuestion: 'Do you realy want to reset the user password?',
                    ResetPassMsgSuccess: 'A new password has been sent to the user email address.',
                    ResetPassMsgError: 'Error reseting the user password.',
                    UsernameInUse: 'Username already in use.',
                    EmailInUse: 'Email already in use.',
                }
            },

            direitos: {
                fields: {
                    name: 'Module',
                    all: 'All',
                    list: 'Access',
                    record: 'Detail',
                    insert: 'Insert',
                    update: 'Update',
                    delete: 'Delete'
                },
                options: {
                    PodeConfirmarPedido: 'Allow confirm orders',
                    PodeMudarPrecoVenda: 'Allow change price',
                    SomentePedidosVendedor: 'Show only sellesman orders',
                    ReprocessarRecebimentos: 'Allow modify receipts',
                    PodeQuitarConta: 'Allow receive/pay bills',
                    SomenteClientesVendedor: `Show only salesman's customers`,
                    vendas: 'Sales',
                    financeiro: 'Banking',
                    helpdesk: 'Help Desk',
                    SomenteVendasParaEntrega: 'Show only sales for delivery',
                    PodeRemoverAssinatura: 'Can remove signatures',
                    MetodoPagtoObrigatorio: 'Mandatory payment method',
                    BloquearMetodoPagtoVenda: 'Block change payment method',
                    BloquearMetodoPagtoCliente: 'Block change payment method',
                    SomenteRotasVendedor: 'Only salesman`s routes',
                    EsconderPrecoCusto: 'Hide cost',
                    PodeSelecionarEstoque: 'Can select stock',
                    PodeSelecionarLote: 'Can select batch',
                    GerenciarTabelasPreco: 'Manage pricing tables',
                    PodeModificarCompletas: 'Can modify Confirmed/Finished',
                    PodeEfetivarAbaixoLucroMinimo: 'Allow confirm orders with profit below min.',
                    MostrarProdutosSemEstoque: 'Show out-of-stock products',
                    PermitirImpCurvaABC: 'Allow printing ABC Curve',
                    PermitirImprimir: 'Allow printing lists',
                    RotaEntrega: 'Allow modify delivery route',
                    PermitirEnviarMsgWhatsApp: 'Allow send WhatsApp messages',
                    PermitirImpTabela: 'Allow print pricing tables',
                    BloquearDataVenda: 'Block changes on date/time',
                    PodeConfirmarPedidoMinimo: 'Allow confirm orders below min. value',
                }
            },

            gruposusuarios: {
                name: 'Users Groups',
                singular: 'Users Group',
                fields: {
                    Name: 'Name',
                    IsAdmin: 'Is Admin'
                },

                texts: {
                    permissions: 'Permissions',
                }
            },

            apikeys: {
                name: 'API Keys',
                singular: 'API Key',
                fields: {
                    Nome: 'Name',
                    Chave: 'Key',
                    Ativa: 'Active',
                },
                texts: {
                    chaveCopiada: 'Key copied to clipboard.'
                }
            },

            motivosperdas: {
                name: 'Reasons for Loss/Consumption',
                singular: 'Reason for Loss/Consumption',
                fields: {
                    Descricao: 'Description',
                    Cor: 'Color',
                    Icone: 'Icon',
                }
            },

            parametros: {
                name: 'Sytem Options',
                singular: 'Sytem Options',

                tabs: {
                    empresa: 'Company',
                    opcoes: 'Options',
                    tabelas: 'Additional Tables',
                    integracoes: 'Integrations',
                },
                fields: {
                    NomeEmpresa: 'Name',
                    NomeFantasia: 'Commercial Name',
                    Contato: 'Contact',
                    CpfCnpj: 'Document',
                    InscricaoEstadual: 'Document 2',
                    PaisId: 'Country',
                    Endereco: 'Address',
                    Numero: 'Number',
                    Complemento: 'Address 2',
                    Bairro: 'Neighborhood',
                    EstadoId: 'State',
                    CidadeId: 'City',
                    CEP: 'Zip',
                    Telefone1: 'Phone 1',
                    Telefone2: 'Phone 2',

                    PedidosVendas: 'Orders and Invoices',
                    EstoquePadrao: 'Default Stock',
                    MostrarVendasPendentesDashboard: 'Show pending invoices on Dashboard',
                    TamanhoFonteImpressao: 'Print Font Size',
                    AssinaturaEntrega: 'Signature on delivery',
                    SeparacaoPedidos: 'Use order picking',
                    NaoUsarEmbalagensSeparacao: 'Do not use packages when order picking.',
                    ObrigarSeparacaoPedidos: 'Enforce order picking',
                    VendedorEcommerce: 'E-commerce Salesman',
                    EsconderPrecosEcommerce: 'Show prices only to logged-in customers',
                    EstoqueCompras: 'Stock and Purchases',
                    CicloCompra: 'Purchase Cicle',
                    AtualizarPreco: 'Update prices in analysis',
                    ComprasCodBar: 'Use Barcodes',
                    PesoProd: 'Products weights required',
                    PedidoMin: 'Minimum Order',

                    MsgSales: 'Sales Message',
                    MsgEcommerce: 'E-commerce Message',
                    EcommerceVenda: 'E-commerce orders as sale',
                    TipoLogo: {
                        name: 'Logo Style',
                        options: {
                            1: 'Image Only',
                            2: 'Image and Company Name',
                        },
                    },
                    TelaRecebimentoAutomatica: 'Show receipt screen automatically',
                    SomenteTabelasCliente: 'Allow only customer pricing tables',
                    ComissaoItem: 'Calculate commisions by items',
                    NaoEntreguesPendente: 'Not delivered invoices as "Pending"',
                    FinanceiroDataEntrega: 'Use delivery date as due date',
                    NaoAtualizarDataEntrega: 'Do not update date on delivery',
                    AddPacoteSeparacao: 'Add package automatically (Order Picking)',
                    OcultarProdutosEstoque: 'Hide out-of-stock products',
                    ItensPedidoOrdemAlfa: 'Print items alphabetically',
                    ObsClienteImpressao: 'Print "Customer Comments"',
                    IgnorarEntrega: 'Ignore delivery',
                    VendasCodBar: 'Use barcodes',
                    NumeroManual: 'Manual order/invoice number',
                    DevolucaoEstoque: 'Returns back to stock',


                    Financeiro: 'Banking',
                    ContaPadrao: 'Default Account',
                    CategoriaTransferencia: 'Transfer Category',
                    CategoriaRecebimentoVenda: 'Receipt Category',
                    CategoriaCreditos: 'Credit Category',
                    QuickBooksSincronizar: 'Synchronize:',
                    SincClientes: 'Customers/Suppliers',
                    SincProdutos: 'Products',
                    SincVendas: 'Purchases/Invoices',
                    Prefixo: 'Prefix Doc. Number',
                    Ressincronizar: 'Resync',

                    ApiPublica: 'Public API',

                    MostrarVencTabela: 'Show next "expirations" in the pricing table printing',
                    MostrarLoteImpressao: 'Print "batch" on the order/invoice',

                    Impressao: 'Printing Options',
                    TamanhoFolha: {
                        name: 'Page Size',
                        Carta: 'Letter',
                        Oficio: 'Legal',
                    },
                    ImprimirPaginaSeparada: 'Print in a new page',

                },
                texts: {
                    QBOModule: 'You dont have access to Quickbooks integration. Contact support.',
                    Conectar: 'Click to connect with: ',
                    Desconectar: 'Disconnect',
                    ReceberProdutos: 'Download Products',
                    DisconnectMessage: 'Do you realy want to disconnect to Quickbooks?',
                    ReceberProdutosMsg: 'Products downloaded successfully.',
                    QuickbooksSync: 'Last Sync:',
                    QuickbooksSyncWaiting: 'Waiting to sync.',
                    Ressincronizar: 'Next sync will resync everything.',
                    WhatsappModule: 'You dont have access to WhatsApp integration. Contact support.',
                    ApiPublicaModule: 'You dont have access to Public API integration. Contact support.',
                }
            }

        },
    }
}

export default enUS;