import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Config from '../../../config';
import useStyles from './styles';
import { Box, Button, Container, Link, Typography, Grid, FormControl, OutlinedInput, InputAdornment, Card, FormHelperText } from '@material-ui/core';
import { useApi } from '../../services/Api';
// import Utils from '../../services/Utils';
import ErrorBox from '../../components/ui/ErrorBox';
import LangMenu from '../../components/layout/MainNavbar/LangMenu';

import packageJson from '../../../../package.json';

import { removeDiacritics, removeSpecial } from '../../services/Utils/strings';
import { useLang } from '../../services/Lang';

const AccountSelect = () => {

    const api = useApi();
    api.notificationsService.close();

    const { lang } = useLang();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [errors, setErrors] = useState({ username: '', password: '', account: '' });

    // const [account] = useState(() => Utils.getAccount(Config));
    const [acc, setAcc] = useState('');

    const [errorMsg, setErrorMsg] = useState('');
    const classes = useStyles();
    const navigate = useNavigate();


    const doSetAccount = async () => {
        setIsSubmitting(true);
        if (!acc) {
            setErrors({ ...errors, account: lang.data.messages.requiredField })
            setIsSubmitting(false);
            return
        }

        try {
            await api.get('_account', true, 'name=' + acc);
        } catch (err: any) {
            setIsSubmitting(false);
            if (err?.code === 1) {
                return setErrorMsg(lang.data.messages.accountNotFound);
            }
            if (err?.code === 2) {
                return setErrorMsg(lang.data.messages.accountSuspended);
            }
            return setErrorMsg(lang.data.messages.errorCheckingAccount);
        }
        setIsSubmitting(false);
        window.location.href = window.location.protocol + '//' + acc + '.' + Config.host + '/';
    }

    return (<>
        <Helmet>
            <title>Login | {Config.name}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
        </Helmet>
        <div className={classes.Root}>
            <Box className={classes.BoxRoot}>
                <Container maxWidth="sm">
                    <Card className={classes.Form}>
                        <Box className={classes.Logo}>
                            <img src="/logo_login.svg" alt="" className={classes.ImgLogo} /> {Config.name}
                            <span className={classes.Tag}>{Config.version}</span>
                        </Box>

                        <div onKeyPress={(evt) => {
                            if (evt.key === 'Enter') {
                                doSetAccount();
                            }
                        }}>
                            <Box className={classes.BoxTextAccount}>
                                <Typography color="textPrimary" variant="h4" >{lang.data.captions.enterAccount}</Typography>
                            </Box>

                            <FormControl variant="outlined" fullWidth>
                                <OutlinedInput
                                    endAdornment={<InputAdornment position="end">{'.' + Config.host}</InputAdornment>}
                                    autoFocus
                                    aria-describedby="filled-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',

                                    }}
                                    error={!!errors.account}
                                    name="account"
                                    onChange={(v) => {
                                        if (errorMsg)
                                            setErrorMsg('')
                                        setAcc(removeSpecial(removeDiacritics((v.target.value || '').trim().toLowerCase())));
                                        setErrors({ ...errors, account: '' })
                                    }}
                                    type="text"
                                    value={acc}
                                />
                                {errors.account && <FormHelperText error >{lang.data.messages.requiredField}</FormHelperText>}
                            </FormControl>

                            <ErrorBox visible={!!errorMsg} style={{ marginTop: 12 }}>
                                {errorMsg}
                            </ErrorBox>

                            <Box sx={{ py: 2 }}>
                                <Button color="primary" disabled={isSubmitting} fullWidth size="large" onClick={doSetAccount} variant="contained">
                                    {lang.data.captions.proceed}
                                </Button>
                            </Box>
                            <Grid container>
                                <Grid item>
                                    <Link href={'/' + (Config.registerEndpoint || "register")} variant="body2" onClick={(evt) => { evt.preventDefault(); navigate('/' + (Config.registerEndpoint || "register")) }}>{lang.data.captions.register}</Link>
                                </Grid>
                            </Grid>
                        </div>

                    </Card>
                </Container>
                {!Config.defaultLang && <Box className={classes.BoxLang}>
                    <Box className={classes.LangButton}>
                        <LangMenu />
                    </Box>
                </Box>}

                <Box className={classes.BoxLang}>
                    <Link href='#' onClick={() => { window.location.reload() }}>{packageJson.version}</Link>
                </Box>
            </Box>
        </div>
    </>);
};

export default AccountSelect;
