import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CustomTheme } from '../../../services/Theme/types';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({

        EditModal: {
            overflowY: 'scroll',
        },

        DontScroll: {
            overflowY: 'hidden',
        },

        Card: {
            position: 'relative',
            marginBottom: theme.spacing(1),
            maxHeight: 'calc(100dvh - 24px)',
        },

        ModalMedium: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            minWidth: 340,

            [theme.breakpoints.down('md')]: {
                width: '96%',
            },
        },

        ModalSmall: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            minWidth: 340,

            [theme.breakpoints.down('md')]: {
                width: '96%',
            },
        },

        ModalLarge: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '96%',
        },

        ModalFull: {
            width: '100dvw',
            height: '100dvh',
            padding: 0,
            margin: 0,
            '& .MuiCard-root': {
                height: '100dvh',
                maxHeight: '100dvh !important',
            }
        },

        ModalCustom: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },

        CloseBtn: {
            color: theme.others.iconColor,
            marginLeft: 'auto',
        },

        Header: {
            display: 'flex',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2)
        },

        Bottom: {
            // alignItems: 'end',
            justifyContent: 'end',
            // textAlign: 'right',
            padding: theme.spacing(2),
            paddingRight: theme.spacing(1),
            '& button': {
                marginRight: theme.spacing(1),
            },

        },


        ContainerModal: {
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: 'calc(100dvh - 150px)',
        },

        ContainerModalNoScroll: {
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            overflowY: 'hidden',
            overflowX: 'hidden',
            maxHeight: 'calc(100dvh - 150px)',
        },

        ContainerModalFull: {
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            overflowY: 'hidden',
            overflowX: 'hidden',
        },

        InnerScroll: {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            [theme.palette.type === 'dark' ? '&' : '']: {
               background: 'unset',
            },
        },

        InnerScrollCloseBtn: {
            color: theme.palette.secondary.contrastText,
            marginLeft: 'auto',
        },

        ContainerFullHeight: {
            height: 'calc(100dvh - 150px)',
        },

        ContainerFull: {
            maxHeight: 'calc(100dvh - 115px) !important',
            height: '100%',
        }
    }),
);

export default useStyles;