import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CustomTheme } from '../../../core/services/Theme/types';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        Title: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(1),
            paddingBottom: 2,
        },
        ContentFull:{
            height: '96vh',
        }
    }),
);

export default useStyles;