import { Helmet } from 'react-helmet';
import { Box, Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import Config from '../../../config';
import { useState } from 'react';
import { useApi } from '../../services/Api';

const Reconnect = () => {
    const [lastPath] = useState(() => localStorage.getItem('lastPath') || '/');
    const [reason] = useState(() => localStorage.getItem('disconnectReason'))
    const api = useApi();

    const doReconnect = () => {
        localStorage.setItem('lastPath', '');
        window.location.pathname = lastPath;
    }


    const doLogout = () => {
        if (api.user)
            api.user.authenticated = false;
        api.notificationsService.close();
        localStorage.setItem('lastPath', '');
        window.location.pathname = '/logout';
    }


    return (
        <>
            <Helmet>
                <title>{api.isStandAlone ? '' : (' | ' + Config.name)}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
            </Helmet>
            <Box
                sx={{
                    bgcolor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                }}
            >

                {/* <Paper elevation={3}> */}
                    <Card style={{
                        maxWidth: 512,
                        padding: 12
                    }}>
                        <CardContent>
                            <Typography variant="h5" color="textSecondary" component="p">
                                Você foi desconectado. Parece que o {Config.name} está aberto em outro dispositivo/navegador ou suas permissões foram modificadas.
                            </Typography>
                            {!!reason && <Typography variant="h6" color="textSecondary" component="p" style={{fontSize: '0.7rem', marginTop: 4}}>
                                {reason}
                            </Typography>}
                        </CardContent>
                        <CardActions style={{
                            display: 'flex',
                        }}>
                            <Button variant='contained' color='primary' onClick={doReconnect}>Reconectar</Button>
                            <Button onClick={doLogout}>Logout</Button>
                        </CardActions>
                    </Card>
                {/* </Paper> */}


            </Box>
        </>
    )
};

export default Reconnect;
