import Config from "../../../config";
import { strToBase64Uint8Array } from "../Utils/strings";
import { Api } from "../Api/Api";
import io, { Socket } from 'socket.io-client';
import User from "../User/types";

export class NotificationService {

    public api?: Api;
    public socket?: Socket;

    initialized: boolean = false;
    interval?: NodeJS.Timeout;

    async getNotifications() {
        try {
            if (!this.api)
                return
            const notifications = await User.getNotifications(false, this.api);
            if (notifications)
                this.api?.user?.setNotifications?.(notifications);
            await this.api.onGetNotified?.();
        } catch (err) {
            // nothing to do
        }
    }

    connectWS() {
        if (!this.api)
            return;
        if (!this.api.user || !this.api.user.userId || !this.api.user.tenantId || !this.api?.user?.authenticated)
            return;

        this.socket = io(this.api.baseUrl === '/api' ? '' : (Config.ws || Config.api), {
            transports: ['websocket'],
            path: Config.wsPath || '/__ws',
            withCredentials: true,
        });
        this.api.notificationsService = this;
        this.initialized = true;

        this.socket.on('connect', () => {
            if (!this.initialized)
                return

            this.interval = setInterval(() => {
                this.getNotifications();
            }, 2 * 60 * 1000)

            this.getNotifications();
            this.sendData('user_connected', {
                t: this.api?.user?.tenantId,
                u: this.api?.user?.userId,
                tb64: this.api?.accB64,
            })
        });

        this.socket.on('disconnect', () => {
            if (this.interval)
                clearInterval(this.interval);
        })

        this.socket.on('user_disconnected', () => {
            this.initialized = false;
            this.close();
            localStorage.setItem('lastPath', window.location.pathname);
            localStorage.setItem('disconnectReason', 'Websocket: user_disconnected');
            this.api?.navigate?.('/reconnect')
        });

        this.socket.on('get_notifications', () => {
            this.getNotifications();
        })
    }

    close() {
        if (this.socket?.connected)
            this.socket.close();
    }

    sendData(eventName: string, data: any) {
        if (!this.initialized)
            throw new Error("Socket not initialized.");
        this.socket?.emit(eventName, data);
    }

    static getPermission() {
        try {
            return Notification.permission;
        } catch (err) {
            return 'denied'
        }
    }

    static async requestPermission(callback?: (perm: NotificationPermission) => void) {
        if (!Config.useDesktopNotifications)
            return
        try {
            const permission = await Notification.requestPermission();
            if (callback)
                callback(permission)
            return permission;
        } catch (err) {
            return 'denied';
        }
    }

    static async registerServiceWorker(api: Api) {
        if (!Config.useDesktopNotifications)
            return
        try {
            const reg = await navigator.serviceWorker.register('/serviceworker.js');
            return await this.subscribe(reg, api);
        } catch (err) {
            return
        }
    }

    static async unregisterServiceWorker(api: Api) {
        if (!Config.useDesktopNotifications)
            return
        try {
            api.secureDelete('_notifications', { a: api.account, }, undefined, true)
            const reg = await navigator.serviceWorker.register('/serviceworker.js');
            reg?.unregister();
        } catch (err) {
            return
        }
    }

    static async subscribe(reg: ServiceWorkerRegistration, api: Api) {
        try {
            const currentSub = await reg.pushManager.getSubscription();
            const pubKey = strToBase64Uint8Array(Config.notificationsPublicKey);
            const currPubKey = new Uint8Array(currentSub?.options.applicationServerKey as ArrayBuffer);
            const eq = pubKey.length === currPubKey?.byteLength && pubKey.every((e, i) => e === currPubKey[i]);

            if (!eq) {
                // console.log('subscribe -> unsubscribe');
                await currentSub?.unsubscribe();
            }
            const sub = await reg.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: pubKey });
            try {
                await api.securePost('_notifications', {
                    sub: sub.toJSON(),
                    a: api.account,
                }, undefined, true, true)
            } catch (err) {
                sub.unsubscribe();
            }
            return sub;
        } catch (err) { /* empty */ }
    }

    static async unsubscribe(api: Api) {
        if (!Config.useDesktopNotifications)
            return
        try {
            const reg = await navigator.serviceWorker.register('/serviceworker.js');
            const sub = await reg.pushManager.getSubscription();
            await sub?.unsubscribe();
        } catch (err) { /* empty */ }
    }
}


export default NotificationService;